import Choices from 'choices.js';

// JSON file
import subcities from '../../../json/subcities.json';

const removeActiveChoices = (mapModal) => {
  const activeChoiceList = mapModal.element.querySelector('.choices__list--multiple');
  activeChoiceList.classList.remove('choices__list--full');
  activeChoiceList.innerHTML = '';
};

const handleModalAttributesChange = (mutationRecords, mapModal) => {
  mutationRecords.forEach((record) => {
    if (record.type === 'attributes' && record.target.classList.contains('modal--active')) {
      removeActiveChoices(mapModal);
    }
  });
};

const addSelectedCity = (value, mapModal, choice) => {
  if (mapModal && mapModal.element.classList.contains(mapModal.activeClass)) {
    window.interactiveMap.addSelectedCity(value);
    choice.removeActiveItemsByValue(value);
    removeActiveChoices(mapModal);
  } else if (choice.passedElement.element.closest('form')) {
    // check if region option has been selected
    if (value.includes('regio-')) {
      const getRegion = value.replace('regio-', '');
      const postcodes = subcities.Plaatsnamen
        .filter(place => place.Hoofdgemeente === getRegion)
        .map(place => place.Postcode);

      choice.setChoiceByValue(postcodes);
    }

    choice.passedElement.element.closest('form').submit();
  } else {
    let url = '';
    const routeUrl = document.querySelector('.route-url').textContent;
    for (let i = 0; i < choice.getValue().length; i++) {
      url += (i > 0 ? '+' : '') + choice.getValue()[i].value;
    }
    if (url !== '') {
      window.location.href = `${routeUrl}/${url}`;
    } else {
      window.location.href = routeUrl;
    }
  }
};

const configureChoice = (el) => {
  const configurations = {
    removeItemButton: el.dataset.removeOption === 'true',
    searchResultLimit: 100,
    fuseOptions: {
      threshold: 0.1,
      distance: 1000,
    },
    shouldSort: true,
    shouldSortItems: true,
    duplicateItemsAllowed: false,
    searchFloor: 1,
    searchFields: ['label'],
  };
  const choice = new Choices(el, { placeholderValue: el.dataset.title, ...configurations });
  if (choice.getValue().length > 0) {
    el.nextElementSibling.classList.add('choices__list--full');
    if (el.dataset.multipleSelectedName && choice.getValue().length > 1) {
      const choicesLength = choice.getValue().length;
      el.nextElementSibling.innerHTML = `
        <div class="choices__item choices__item--selectable" data-item="" data-id="" data-value="" data-custom-properties="" aria-selected="true" data-deletable="">
          ${choicesLength} ${el.dataset.multipleSelectedName}
        </div>`;
    }
  }

  return choice;
};

const initChoices = () => {
  const url = window.location.href;
  const mapModal = window.modalManager.idModalMap.get('map-modal');
  const elements = document.querySelectorAll('.js-choice');
  const observer = new MutationObserver(mutationRecords => handleModalAttributesChange(mutationRecords, mapModal));
  elements.forEach((el) => {
    const choice = configureChoice(el);
    if (window.choiceInputs) {
      window.choiceInputs = [...window.choiceInputs, { type: el.dataset.type, input: choice }];
    } else {
      window.choiceInputs = [{ type: el.dataset.type, input: choice }];
    }

    if (mapModal) {
      observer.observe(mapModal.element, {
        attributes: true,
        attributeFilter: ['class'],
        childList: false,
        characterData: false,
      });
    }

    choice.passedElement.element.addEventListener('search', (e) => {
      document.querySelector('.choices__list--dropdown.is-active').style.opacity = 1;
      document.querySelector('.choices__list--dropdown.is-active').style.pointerEvents = 'all';

      const searchWrapper = document.querySelector('.choices__list--dropdown.is-active .choices__list');
      const searchResults = document.querySelectorAll('.choices__list--dropdown.is-active .choices__item');
      if (searchResults.length) {
        const resultItems = Array.from(searchResults).map((result) => {
          const regex = /[\d()]/g;
          const resultLabel = result.innerText
            .replace('regio-', '')
            .replace(regex, '')
            .replace('+ deelgemeentes', '')
            .replace(' ', '');

          return { label: resultLabel, itemHTML: result };
        });

        const sortedResults = resultItems.sort((a, b) => a.label.localeCompare(b.label));

        searchWrapper.innerHTML = '';
        sortedResults.forEach(({ itemHTML }) => {
          searchWrapper.appendChild(itemHTML);
        });

        const isInteractiveMapHeader = e.target.closest('.interactive-map__header') !== null;
        if (!isInteractiveMapHeader) {
          document.querySelectorAll('[data-value="9830"]').forEach((value) => {
            value.classList.add('hide');
            value.style.opacity = 0;
            value.style.height = 0;
          });
        }
      }
    });

    const targetNodes = document.querySelectorAll('input[type="search"][aria-label="Kies uw gemeente"]');
    const config = { attributes: true, childList: true, subtree: true };
    const callback = (mutationList, observer) => {
      mutationList.forEach((record) => {
        if (record.target.value === '') {
          document.querySelector('.choices__list--dropdown.is-active').style.opacity = 0;
          document.querySelector('.choices__list--dropdown.is-active').style.pointerEvents = 'none';
        }
      });
    };

    const observerSearchInput = new MutationObserver(callback);
    targetNodes.forEach(targetNode => observerSearchInput.observe(targetNode, config));

    choice.passedElement.element.addEventListener('change', e => addSelectedCity(e.detail.value, mapModal, choice));
  });

  const arrOfUrl = url.split('?zip');
  if (arrOfUrl.length > 1) {
    document.querySelector('.js-button-invisible').classList.remove('vh');
  }
};

export default initChoices;
